import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Sidetalk: Updated audioplayer.`}</li>
      <li parentName="ul">{`Tags: Updated Tag Domain/URL to Coremedia.`}</li>
      <li parentName="ul">{`Aggregation Panels: Improved aggregation panel loader for details/graph.*`}</li>
      <li parentName="ul">{`IVR: added AI accordion`}</li>
      <li parentName="ul">{`Chatbot & Automation Studio: UI Improvements`}</li>
      <li parentName="ul">{`Bug Fix: Search update classified tab.`}</li>
      <li parentName="ul">{`Bug Fix: Login - footer overlaps login button.`}</li>
      <li parentName="ul">{`Bug Fix: Aggregation by Date on Contact Center Activity.*`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      